.app__fullmenu {
  display: flex;
  flex-direction: column;
  background-color: var(--color-black);
}

.orderItMenu {
  width: 100%;
  /* padding: 0 6rem; */
  background: var(--color-black);
  text-align: center;
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  padding-bottom: 3rem;

  @media screen and (max-width: 600px) {
    /* padding: 0.5rem; */
    font-size: 28px;
  }
  
}

.demoImg {
  display: block;
  width: 100%;
  height: auto;
}
