:root {
  --toastify-color-progress-light: #dcca87;
}
.app__newsletter {
  padding: 2rem 4rem;
  border: 1px solid var(--color-golden);
  background: var(--color-black);
  border-radius: 10px;
}

.app__newsletter-heading {
  text-align: center;
}

.contact_text{
  color: var(--color-golden);
  font-size: 2rem;
}
.app__newsletter-input {
  flex-direction: row;
  margin-top: 3rem;
}
.app__newsletter-input input {
  width: 620px;
  border: 1px solid var(--color-golden);
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--font-base);
  color: var(--color-white);

  margin-right: 2rem;
  padding: 0.75rem 1rem;
  background: var(--color-black);
}

.app__newsletter-input button {
  width: max-content;
}

.Toastify__toast {
  background-color: black;
  color: var(--color-golden);
  /* opacity: 0.7; */
}
.Toastify__progress-bar-theme--light {
  color: var(--color-golden);
  background-color: var(--color-golden);
}
@media screen and (max-width: 2000px) {
  .app__newsletter-input {
    font-size: 2rem;
  }
  .alert-box {
    font-size: 27px;
    /* background-color: black; */
    /* width: 350px; */
    color: black;
  }
}

@media screen and (max-width: 990px) {
  .app__newsletter-input {
    flex-direction: column;
    width: 100%;
  }

  .app__newsletter-input input {
    margin: 0 0 2rem 0;
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  .app__newsletter {
    padding: 2rem 0;
    border: none;
  }
  .alert-box {
    width: 100px;
    /* background-color: black; */
    font-size: 18px;
  }
  .Toastify__toast {
    background-color: black;
    color: #dcca87;
    color: #dcca87;
  }
}
@media screen and (max-width: 300px) {
  .app__newsletter-heading {
    font-size: 32px;
    line-height: 50px;
  }
}
