.app__login-page {
  padding: 2rem 4rem;
  border-top: 1px solid var(--color-golden);
  /* background-color: var(--color-black); */
  /* margin-bottom: 3rem; */
  /* height: 80vh; */
  background-color: var(--color-black);
  height: auto;
  display: flex;
  /* overflow: hidden; */
}

.app__login-page::-webkit-scrollbar {
  display: none;
}
.app__login {
  /* background: url("../../assets/bg.webg"); */
  background-color: var(--color-black);
  height: auto;
  border-top: 1px solid var(--color-golden);
  /* flex-direction: column; */
  /* overflow: hidden; */
}
.app__login-input input {
  width: 620px;
  border: 1px solid var(--color-golden);
  border-radius: 5px;
  font-size: 1rem;
  color: var(--color-white);
  font-family: var(--font-base);
  padding: 0.55rem 1rem;
  background-color: var(--color-black);
}
.login_button {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  /* margin-left: 13rem; */
}
.custom__link {
  margin-right: 1.5rem;
  padding: 0.2rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 1rem;
  border: 1px solid var(--color-golden);
  font-family: var(--font-base);
  color: white;
}
.app__login-design {
  margin-bottom: 3rem;
}
.login-para {
  margin-bottom: 1rem;
}
.app__login-text {
  font-family: var(--font-base);
  margin-top: 1rem;
  color: white;
  /* margin-bottom: 9px; */
  font-size: 1.5rem;
}
.login-mini {
  /* font-family: var(--font-base); */
  margin-left: 13px;
  /* font-size: 1.4rem; */
  color: var(--color-golden);
  cursor: pointer;
}
.app__login-video {
  /* width: 100%; */
  display: flex;
  position: relative;
}
.app__video-overlay_circle {
  position: absolute;
}
.app__login-video video {
  /* margin-top: 3rem; */
  height: 600px;
  /* width: 100%; */
  width: 95%;
  margin-left: 3rem;
}
@media screen and (max-width: 800px) {
  .app__login-input input {
    width: 110%;
    padding: 0.5rem 1rem;
  }
  .app__login-text {
    font-size: 1.7rem;
  }
  .login_button {
    margin-left: 17%;
  }
  .text {
    margin-left: 1.3rem;
  }
}
